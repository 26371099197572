import { mergeProps } from 'solid-js';
import { A, useLocation } from '@solidjs/router';
import { twMerge } from '@troon/tailwind-preset/merge';
import { Dynamic } from 'solid-js/web';
import { useFocusable } from '../providers/focusable';
import { useHost } from '../providers/global';
import type { ComponentProps } from 'solid-js';

type Props = ComponentProps<typeof A> & {
	href: string;
};

export function Link(inputProps: Props) {
	const location = useLocation();
	const focusableProps = useFocusable<Props>();
	const props = mergeProps(focusableProps, inputProps);
	const host = useHost();

	return (
		<Dynamic
			component={/^(\/|http)/.test(props.href) ? A : 'a'}
			aria-current={location.pathname === props.href ? 'page' : undefined}
			{...props}
			class={twMerge(
				'rounded outline-none focus-visible:ring-1 focus-visible:ring-brand-500 focus-visible:ring-offset-2',
				props.class,
			)}
			href={props['aria-disabled'] ? '' : withTrailingSlash(host, props.href)}
		/>
	);
}

export function TextLink(props: Props) {
	return (
		<Link
			{...props}
			class={twMerge(
				'text-brand underline-offset-2 transition-colors duration-200 hover:underline hover:decoration-solid focus-visible:ring-1 focus-visible:ring-brand-500 focus-visible:ring-offset-2 active:text-brand-700',
				props.class,
			)}
		/>
	);
}

function withTrailingSlash(host: string, href: string) {
	if (/^(http|tel:)/.test(href)) {
		return href;
	}
	const url = new URL(href, host);
	if (url.pathname.endsWith('/')) {
		return href;
	}

	url.pathname = `${url.pathname}/`;
	return url.toString().replace(host, '');
}
