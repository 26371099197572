import { Show, createContext, createEffect, createUniqueId, onCleanup, useContext } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import type { ParentProps } from 'solid-js';
import type { SetStoreFunction, Store } from 'solid-js/store';

type StoreData = {
	focused: string | undefined;
	selected: string | undefined;
	panelId: string;
	tabs: Array<string>;
};

const initialData: StoreData = { focused: undefined, selected: undefined, panelId: '', tabs: [] };

const Context = createContext<[Store<StoreData>, SetStoreFunction<StoreData>]>(createStore<StoreData>(initialData));

export function Tabs(props: ParentProps) {
	const panelId = createUniqueId();
	const [tabs, setTabs] = createStore<StoreData>({ ...initialData, panelId });

	createEffect(() => {
		if (!tabs.selected && tabs.tabs.length > 0) {
			setTabs(
				produce((s) => {
					s.selected = s.tabs[0];
				}),
			);
		}
	});

	function handleKeypress(e: KeyboardEvent) {
		const delta =
			e.key === 'ArrowDown' || e.key === 'ArrowRight' ? 1 : e.key === 'ArrowUp' || e.key === 'ArrowLeft' ? -1 : 0;
		if (delta) {
			setTabs(
				produce((s) => {
					const curIndex = s.tabs.indexOf(s.selected!);
					const nextIndex = curIndex + delta;
					s.selected = s.tabs[nextIndex === s.tabs.length ? 0 : nextIndex === -1 ? s.tabs.length - 1 : nextIndex];
					s.focused = s.selected;
				}),
			);
			return;
		}

		if (e.key === 'Home' || e.key === 'End') {
			setTabs(
				produce((s) => {
					s.selected = s.tabs[e.key === 'Home' ? 0 : s.tabs.length - 1];
					s.focused = s.selected;
				}),
			);
		}
	}

	return (
		<Context.Provider value={[tabs, setTabs]}>
			<div onKeyDown={handleKeypress}>{props.children}</div>
		</Context.Provider>
	);
}

export function TabList(props: ParentProps<{ label: string }>) {
	return (
		<div
			aria-label={props.label}
			role="tablist"
			class="mb-4 flex flex-row flex-wrap justify-start gap-x-4 border-b border-b-neutral"
		>
			{props.children}
		</div>
	);
}

export function Tab(props: ParentProps<{ for: string }>) {
	const [tabs, setTabs] = useContext(Context);
	let ref: HTMLDivElement;

	setTabs(produce((s) => s.tabs.push(props.for)));

	onCleanup(() => {
		setTabs(
			produce((s) => {
				s.tabs = s.tabs.filter((tab) => tab !== props.for);
			}),
		);
	});

	createEffect(() => {
		if (ref && tabs.focused === props.for) {
			ref.focus();
		}
	});

	return (
		<div
			ref={ref!}
			role="tab"
			tabindex={tabs.selected === props.for ? 0 : -1}
			aria-controls={tabs.selected === props.for ? `${tabs.panelId}-${props.for}` : undefined}
			aria-selected={tabs.selected === props.for}
			class="relative my-2 cursor-pointer rounded font-semibold text-neutral-800 outline-none after:absolute after:inset-x-0 after:-bottom-2 after:border-b-2 after:border-b-transparent hover:after:border-b-brand-200 focus-visible:ring-4 focus-visible:ring-brand-100 aria-selected:text-neutral-950 aria-selected:after:border-b-brand"
			onClick={() => {
				setTabs(
					produce((s) => {
						s.selected = props.for;
						s.focused = props.for;
					}),
				);
			}}
		>
			{props.children}
		</div>
	);
}

export function TabPanel(props: ParentProps<{ id: string }>) {
	const [tabs] = useContext(Context);
	return (
		<Show when={(!tabs.selected && tabs.tabs.indexOf(props.id) === 0) || tabs.selected === props.id}>
			<div
				id={`${tabs.panelId}-${props.id}`}
				role="tabpanel"
				// tabindex={0}
				class="rounded outline-none focus-visible:ring-4 focus-visible:ring-brand-100 focus-visible:ring-offset-2"
			>
				{props.children}
			</div>
		</Show>
	);
}
