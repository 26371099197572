import { twMerge, twJoin } from '@troon/tailwind-preset/merge';
import { Show, mergeProps, splitProps, useContext } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { useFocusable } from '../providers/focusable';
import { FormContext } from './form';
import { ActivityIndicator } from './activity-indicator';
import type { JSX, ComponentProps } from 'solid-js';
import type { Link } from './link';

type AllProps = {
	action?: 'danger';
	appearance?: 'primary' | 'secondary' | 'secondary-current' | 'transparent' | 'transparent-current';
	disabled?: boolean;
};
type LinkProps<T extends typeof Link = typeof Link> = {
	as: T;
} & AllProps &
	ComponentProps<T>;

type ButtonProps = {
	as?: 'button' | undefined;
} & AllProps &
	JSX.ButtonHTMLAttributes<HTMLButtonElement>;

type Props = LinkProps | ButtonProps;

export function Button(inputProps: Props) {
	const { data } = useContext(FormContext);
	const focusableProps = useFocusable<Props>();
	const allProps = mergeProps({ as: 'button', appearance: 'primary' as const }, focusableProps, inputProps);
	const [props, rest] = splitProps(allProps, ['as', 'children', 'action', 'appearance', 'class']);

	return (
		<Dynamic
			{...rest}
			component={props.as}
			disabled={props.as === 'button' && rest.type === 'submit' ? data?.pending || rest.disabled : rest.disabled}
			aria-disabled={props.as !== 'button' ? data?.pending || rest.disabled : rest.disabled}
			formnovalidate
			class={twMerge(
				twJoin(
					'flex grow cursor-pointer flex-wrap items-center justify-center gap-x-2 whitespace-nowrap text-wrap rounded-lg border text-center font-semibold outline-none transition-all duration-200 focus-visible:ring active:scale-95 aria-disabled:cursor-not-allowed aria-disabled:active:scale-100',
					props.appearance === 'primary' && !props.action
						? 'border-brand bg-brand px-4 py-2.5 uppercase text-white hover:border-brand-600 hover:bg-brand-600 focus-visible:ring-brand-100 active:border-brand-700 active:bg-brand-700 aria-disabled:border-neutral-300 aria-disabled:bg-neutral-300 aria-disabled:text-neutral-700 aria-disabled:hover:border-neutral-300 aria-disabled:hover:bg-neutral-300 aria-disabled:active:border-neutral-300 aria-disabled:active:bg-neutral-300'
						: '',
					props.appearance === 'primary' && props.action === 'danger'
						? 'border-red-500 bg-red-500 px-4 py-2.5 uppercase text-white hover:border-red-600 hover:bg-red-600 focus-visible:ring-red-100 active:border-red-700 active:bg-red-700 aria-disabled:border-red-200 aria-disabled:bg-red-200 aria-disabled:text-red-600 aria-disabled:hover:border-red-200 aria-disabled:hover:bg-red-200 aria-disabled:active:border-red-200 aria-disabled:active:bg-red-200'
						: '',

					props.appearance === 'secondary' && !props.action
						? ' border-brand bg-white px-4 py-2.5 uppercase text-brand hover:bg-brand-100 focus-visible:ring-brand-100 active:bg-brand-100 aria-disabled:border-neutral-300 aria-disabled:text-neutral-700 aria-disabled:hover:bg-white aria-disabled:active:bg-white'
						: '',
					props.appearance === 'secondary' && props.action === 'danger'
						? 'border-red-500 bg-white px-4 py-2.5 uppercase text-red-500 hover:bg-red-100 focus-visible:ring-red-100 active:bg-red-100 aria-disabled:border-red-300 aria-disabled:text-red-300 aria-disabled:hover:bg-white aria-disabled:active:bg-white'
						: '',
					props.appearance === 'secondary-current' && !props.action
						? ' border-current bg-transparent px-4 py-2.5 uppercase text-current hover:bg-current/10 focus-visible:ring-current/30 active:bg-current/30 aria-disabled:border-neutral-700 aria-disabled:text-neutral-700 aria-disabled:hover:bg-transparent aria-disabled:active:bg-transparent'
						: '',

					props.appearance === 'transparent' && !props.action
						? 'border-transparent px-4 py-2.5 uppercase text-brand hover:bg-brand-100 focus-visible:ring-brand-100 active:bg-brand-200 aria-disabled:bg-transparent aria-disabled:text-neutral-700'
						: '',

					props.appearance === 'transparent' && props.action === 'danger'
						? 'border-transparent px-4 py-2.5 uppercase text-red-500 hover:bg-red-100 focus-visible:ring-red-100 active:bg-red-200 aria-disabled:bg-transparent aria-disabled:text-neutral-700'
						: '',

					props.appearance === 'transparent-current'
						? 'border-transparent px-4 py-2.5 uppercase text-current hover:bg-current/10 focus-visible:ring-current/30 active:bg-current/30 aria-disabled:bg-transparent aria-disabled:text-neutral-700'
						: '',
				),
				props.class,
			)}
		>
			<Show when={data.pending && rest.type === 'submit'}>
				<ActivityIndicator aria-label="Loading" class="me-2 size-4" />
			</Show>
			{props.children}
		</Dynamic>
	);
}
