import dayjs from 'dayjs';
import { For, createEffect, createMemo, createSignal } from 'solid-js';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { twMerge } from '@troon/tailwind-preset/merge';
import { IconChevronLeft, IconChevronRight } from '@troon/icons';
import { Dynamic } from 'solid-js/web';
import type { Dayjs } from 'dayjs';
import type { ParentProps } from 'solid-js';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

type HTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type CalendarProps = ParentProps<{
	headingLevel?: HTag;
	minDate?: Dayjs;
	maxDate?: Dayjs;
	label?: string;
	dayLabel?: string;
	onSelect?: (date: Dayjs) => void;
	onFocus?: (date: Dayjs) => void;
	selectedDate?: Dayjs;
}>;

export function Calendar(props: CalendarProps) {
	const [focusedDate, setFocusedDate] = createSignal<Dayjs>(props.selectedDate ?? dayjs());
	const [currentMonth, setCurrentMonth] = createSignal<Dayjs>((props.selectedDate ?? dayjs()).startOf('month'));
	let calendar: HTMLTableSectionElement;

	const days = createMemo<Array<Array<{ date: Dayjs; isSameMonth: boolean }>>>(() => {
		const daysInMonth = currentMonth().clone().endOf('month').date();
		const weeksInMonth = Math.ceil(daysInMonth / 7 + 2);
		const startDate = currentMonth().startOf('week');

		return new Array(weeksInMonth).fill(0).reduce(
			(memo, _week, weekIndex) => {
				const firstDayOfWeek = startDate.clone().add(weekIndex * 7, 'day');
				if (weekIndex > 0 && !firstDayOfWeek.isSame(currentMonth(), 'month')) {
					return memo;
				}
				memo.push(
					new Array(7).fill(0).map((_, dayIndex) => {
						const date = firstDayOfWeek.clone().add(dayIndex, 'day');
						return { date, isSameMonth: date.isSame(currentMonth(), 'month') };
					}),
				);
				return memo;
			},
			[] as Array<Array<{ date: Dayjs; isSameMonth: boolean }>>,
		);
	});

	createEffect(() => {
		props.onFocus && props.onFocus(focusedDate());
		if (focusedDate().isBefore(currentMonth(), 'month')) {
			setCurrentMonth((current) => current.subtract(1, 'month'));
		} else if (focusedDate().isAfter(currentMonth(), 'month')) {
			setCurrentMonth((current) => current.add(1, 'month'));
		}
	});

	function handleKeypress(event: KeyboardEvent) {
		let nextDate = focusedDate();
		switch (event.key) {
			case 'Enter':
				event.preventDefault();
				props.onSelect && props.onSelect(nextDate);
				return;
			case 'ArrowDown':
				event.preventDefault();
				nextDate = nextDate.add(7, 'day');
				break;
			case 'ArrowUp':
				event.preventDefault();
				nextDate = nextDate.subtract(7, 'day');
				break;
			case 'ArrowRight':
				event.preventDefault();
				nextDate = nextDate.add(1, 'day');
				break;
			case 'ArrowLeft':
				event.preventDefault();
				nextDate = nextDate.subtract(1, 'day');
				break;
			default:
				// no default
				return;
		}

		// Clamp to min/max
		if (props.minDate && nextDate.isBefore(props.minDate)) {
			nextDate = props.minDate;
		} else if (props.maxDate && nextDate.isAfter(props.maxDate)) {
			nextDate = props.maxDate;
		}

		setFocusedDate(nextDate);

		setTimeout(() => {
			(calendar.querySelector('[tabindex="0"]') as HTMLButtonElement | undefined)?.focus();
		}, 1);
	}

	return (
		<div role="application" class="w-max">
			<header class="grid grid-cols-7 items-center">
				<button
					disabled={props.minDate && focusedDate().startOf('month').isSameOrBefore(props.minDate)}
					aria-label={`Go to ${focusedDate().subtract(1, 'month').format('MMMM YYYY')}`}
					onClick={() => {
						setFocusedDate((f) => f.subtract(1, 'month'));
					}}
					class="col-span-1 size-9 cursor-pointer rounded-full text-center outline-none transition-colors duration-100 focus-visible:ring-2 focus-visible:ring-brand-700 enabled:hover:bg-brand-100 enabled:hover:text-black enabled:active:ring-2 enabled:active:ring-brand-700 disabled:cursor-default disabled:text-neutral-500"
				>
					<IconChevronLeft />
				</button>
				<Dynamic component={props.headingLevel ?? 'h2'} class="col-span-5 text-center font-semibold">
					{focusedDate().format('MMMM YYYY')}
				</Dynamic>
				<button
					disabled={props.maxDate && focusedDate().endOf('month').isSameOrAfter(props.maxDate)}
					aria-label={`Go to ${focusedDate().add(1, 'month').format('MMMM YYYY')}`}
					onClick={() => {
						setFocusedDate((f) => f.add(1, 'month'));
					}}
					class="col-span-1 size-9 cursor-pointer rounded-full text-center outline-none transition-colors duration-100 focus-visible:ring-2 focus-visible:ring-brand-700 enabled:hover:bg-brand-100 enabled:hover:text-black enabled:active:ring-2 enabled:active:ring-brand-700 disabled:cursor-default disabled:text-neutral-500"
				>
					<IconChevronRight />
				</button>
			</header>
			<table
				class="bg-white"
				role="grid"
				aria-label={`${(props.label ?? '{date}').replace('{date}', focusedDate().format('MMMM YYYY'))}`}
				onClick={() => {
					(calendar.querySelector('[tabindex="0"]') as HTMLButtonElement | undefined)?.focus();
				}}
			>
				<thead aria-hidden>
					<tr>
						<For each={days()[0]}>{(day) => <th>{day.date.format('dd')}</th>}</For>
					</tr>
				</thead>
				<tbody ref={calendar!}>
					<For each={days()}>
						{(week) => (
							<tr>
								<For each={week}>
									{(day) => {
										const withinRange =
											day.date.isSameOrAfter(props.minDate ?? -8640000000000000, 'day') &&
											day.date.isSameOrBefore(props.maxDate ?? 8640000000000000, 'day');

										return (
											<td
												tabindex={focusedDate().isSame(day.date, 'day') ? 0 : -1}
												role="button"
												aria-label={`${(props.dayLabel ?? '{date}').replace('{date}', day.date.format('dddd, MMMM D, YYYY'))}`}
												class={twMerge(
													'size-9 cursor-pointer rounded-full text-center outline-none transition-colors duration-100 aria-disabled:cursor-default aria-disabled:text-neutral-500',
													day.date.isSame(dayjs(), 'day') &&
														!day.date.isSame(focusedDate(), 'day') &&
														'bg-neutral-500/50',
													day.date.isSame(focusedDate(), 'day') && 'bg-brand text-white',
													withinRange &&
														'hover:bg-brand-100 hover:text-black focus-visible:ring-2 focus-visible:ring-brand-700 active:ring-2 active:ring-brand-700',
												)}
												aria-disabled={!withinRange}
												onKeyDown={handleKeypress}
												onClick={
													withinRange
														? () => {
																setFocusedDate(day.date);
																props.onSelect && props.onSelect(day.date);
															}
														: undefined
												}
											>
												{day.date.format('D')}
											</td>
										);
									}}
								</For>
							</tr>
						)}
					</For>
				</tbody>
			</table>
		</div>
	);
}
