import { createEffect, createUniqueId, useContext, For, splitProps, createContext, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { twMerge } from '@troon/tailwind-preset/merge';
import { FormContext } from './form.jsx';
import type { Accessor, ComponentProps, JSX, ParentProps } from 'solid-js';
import type { Label } from './label';

type Store<T extends Record<string, unknown> | Array<unknown>> = ReturnType<typeof createStore<T>>;

export type InputCTX = Partial<
	JSX.AriaAttributes & { 'aria-describedby'?: string; id: string; name: string; required: boolean; readonly: boolean }
>;
export const InputContext = createContext<Store<InputCTX>>(createStore<InputCTX>({}));

export type LabelCTX = Partial<ComponentProps<typeof Label>>;
export const LabelContext = createContext<Store<LabelCTX>>(createStore<LabelCTX>({}));

export type DescriptionCTX = { id: string };
export const DescriptionContext = createContext<{
	descriptions: Array<DescriptionCTX>;
	add: (props: DescriptionCTX) => void;
	remove: (props: DescriptionCTX) => void;
}>({ descriptions: [], add: () => {}, remove: () => {} });

export type FieldProps = ParentProps<{
	'aria-describedby'?: string;
	class?: string;
	name: string;
	id?: string;
	required?: boolean;
}>;

export function Field(props: FieldProps) {
	const [, wrapperProps] = splitProps(props, ['name', 'required']);
	const { fieldErrors, required } = useContext(FormContext);
	const id = createUniqueId();
	const inputStore = createStore<InputCTX>({
		'aria-describedby': props['aria-describedby'],
		id: props.id ?? id,
		name: props.name,
		required: !!required[props.name],
	});
	const [, setInput] = inputStore;
	const [parentLabel] = useContext(LabelContext);
	const labelStore = createStore<LabelCTX>({
		for: props.id ?? id,
		required: !Object.keys(parentLabel).length ? !!required[props.name] : false,
	});
	const [descriptions, setDescriptions] = createStore<Array<DescriptionCTX>>([]);
	const [errors, setErrors] = createSignal<Array<string>>([]);

	function addDescription(props: DescriptionCTX) {
		setDescriptions([...descriptions, props]);
	}

	function removeDescription(props: DescriptionCTX) {
		setDescriptions(descriptions.filter((p) => p.id !== props.id));
	}

	createEffect(() => {
		const descIds = descriptions.map((desc) => desc.id ?? '');
		setErrors(fieldErrors()[props.name] ?? []);
		if (fieldErrors()[props.name] && fieldErrors()[props.name]!.length) {
			setInput('aria-invalid', true);
		} else {
			setInput('aria-invalid', undefined);
		}
		const errIds = (fieldErrors()[props.name] ?? []).map((err, i) => `${props.id ?? id}-err-${i}`);
		setInput('aria-describedby', `${props['aria-describedby'] ?? ''} ${[...descIds, ...errIds].join(' ')}`);
	});

	return (
		<InputContext.Provider value={inputStore}>
			<LabelContext.Provider value={labelStore}>
				<DescriptionContext.Provider value={{ descriptions, add: addDescription, remove: removeDescription }}>
					<ErrorContext.Provider value={errors}>
						<div {...wrapperProps}>{wrapperProps.children}</div>
					</ErrorContext.Provider>
				</DescriptionContext.Provider>
			</LabelContext.Provider>
		</InputContext.Provider>
	);
}

export const ErrorContext = createContext<Accessor<Array<string>>>(() => []);

export function FieldErrors(props: JSX.HTMLAttributes<HTMLDivElement>) {
	const [input] = useContext(InputContext)!;
	const errors = useContext(ErrorContext);

	return (
		<For each={errors()}>
			{(issue, i) => (
				<div
					{...props}
					id={`${input.id ?? ''}-err-${i()}`}
					class={twMerge('text-red-500', props.class)}
					aria-live="polite"
				>
					{issue}
				</div>
			)}
		</For>
	);
}
