import { createContext, createSignal, useContext, createUniqueId, createEffect, Show } from 'solid-js';
import { Dynamic, Portal, isServer } from 'solid-js/web';
import { createStore } from 'solid-js/store';
import { IconCloseMd } from '@troon/icons';
import { twJoin, twMerge } from '@troon/tailwind-preset/merge';
import { FocusableProvider } from '../providers/focusable';
import { Button } from '../components/button';
import { safeTransition } from '../modules/view-transitions';
import { useDialogContext } from '../providers/dialog';
import type { Accessor, ParentProps, JSX, ComponentProps } from 'solid-js';

const Context = createContext<{
	id: string;
	triggerId: string;
	triggerRef: Accessor<HTMLButtonElement | undefined>;
}>({
	id: '',
	triggerId: '',
	triggerRef: () => undefined,
});
const [store, setStore] = createStore<Record<string, boolean>>({});

export function DialogTrigger(props: ParentProps) {
	const id = createUniqueId();
	const triggerProps = useTrigger(id);
	const [triggerRef, setTriggerRef] = createSignal<HTMLButtonElement>();

	return (
		<Context.Provider value={{ id, triggerId: triggerProps.id ?? '', triggerRef }}>
			<FocusableProvider {...triggerProps} ref={setTriggerRef}>
				{props.children}
			</FocusableProvider>
		</Context.Provider>
	);
}

type NoHeaderProps = {
	header?: never | undefined;
	headerLevel?: never | undefined;
};
type HeaderProps = {
	header: JSX.Element;
	headerLevel: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

type Props = {
	/**
	 * A unique identifier for the onDialogOpen and onDialogClose events
	 */
	key: string;
	children: (handleClose: () => void) => JSX.Element;
	noClose?: boolean;
	nonModal?: boolean;
	onClose?: () => void;
	autoHeight?: boolean;
} & (NoHeaderProps | HeaderProps);

export function Dialog(props: Props) {
	let ref: HTMLDialogElement;
	const ctx = useContext(Context);
	const { onDialogOpened, onDialogClosed } = useDialogContext();
	const headerId = createUniqueId();

	function handleClose() {
		onDialogClosed(props.key);
		props.onClose && props.onClose();
		safeTransition(() => {
			setStore(ctx.id, false);
			ctx.triggerRef()?.focus();
		});
	}

	// You might be tempted to use `createRenderEffect`,
	// But that would run before the dialog is written to the DOM
	createEffect(() => {
		if (store[ctx.id] && ref && !isServer) {
			onDialogOpened(props.key);
			safeTransition(() => {
				props.nonModal ? ref!.show() : ref!.showModal();
				setTimeout(() => {
					(ref.querySelector('[tabIndex="0"]') as HTMLInputElement)?.focus();
				}, 300);
			});
		}
	});

	return (
		<Show when={store[ctx.id]}>
			<Portal>
				<div class="pointer-events-none fixed inset-0 z-40 flex items-center justify-center">
					<dialog
						ref={ref!}
						id={ctx.id}
						onClose={handleClose}
						aria-labelledby={props.header ? headerId : undefined}
						// eslint-disable-next-line tailwindcss/no-arbitrary-value
						class={twJoin(
							'pointer-events-auto fixed bottom-0 mb-0 mt-auto w-dvw max-w-full overflow-y-auto overflow-x-hidden overscroll-contain rounded-t-md bg-white shadow-xl vt-dialog backdrop:bg-black/50 backdrop:backdrop-blur-sm sm:my-auto sm:size-fit sm:w-full sm:max-w-xl sm:rounded-md',
							!props.autoHeight ? 'h-[97dvh]' : 'max-h-[97dvh]',
						)}
					>
						<FocusableProvider>
							<Show when={!props.noClose || props.header}>
								<div
									class={twMerge(
										'flex flex-row items-center justify-between gap-4 bg-white py-3 pe-0 ps-6',
										!!props.header && 'mb-4 border-b border-neutral-500',
									)}
								>
									<Show when={!!props.header}>
										<Dynamic component={props.headerLevel} class="grow font-semibold" id={headerId}>
											{props.header}
										</Dynamic>
									</Show>
									<Show when={!props.noClose}>
										<div class="ms-auto shrink pe-2">
											<Button appearance="transparent" onClick={handleClose} tabindex={1}>
												<IconCloseMd />
												<span class="sr-only">Close</span>
											</Button>
										</div>
									</Show>
								</div>
							</Show>
							<div class="self-start px-4 pb-6 md:px-6" classList={{ 'pt-6': props.noClose && !props.header }}>
								{props.children(handleClose)}
							</div>
						</FocusableProvider>
					</dialog>
				</div>
			</Portal>
		</Show>
	);
}

function useTrigger(id: string): JSX.HTMLAttributes<HTMLElement> {
	const buttonId = createUniqueId();
	const [attrs, setAttrs] = createStore<JSX.HTMLAttributes<HTMLElement>>({
		id: buttonId,
		'aria-expanded': false,
		'aria-controls': undefined,
		onClick: function onClick(e) {
			if (e.metaKey || e.ctrlKey || e.altKey || e.shiftKey) {
				return;
			}
			e.preventDefault();
			setStore(id, true);
		},
	});

	createEffect(() => {
		if (store[id]) {
			setAttrs({ 'aria-controls': id, 'aria-expanded': true });
		}
	});

	return attrs;
}

export function DialogActions(props: ParentProps) {
	return (
		<div class="flex flex-col items-stretch justify-start gap-4 sm:flex-row-reverse sm:items-center">
			{props.children}
		</div>
	);
}

export function DialogAction(props: ComponentProps<typeof Button>) {
	return <Button {...props} class="shrink grow-0" />;
}
