import { createEffect } from 'solid-js';
import { computePosition, offset, flip, autoUpdate } from '@floating-ui/dom';
import { isServer } from 'solid-js/web';
import type { ComputePositionConfig } from '@floating-ui/dom';
import type { Accessor } from 'solid-js';

type MaybeAccessor<T extends HTMLElement | undefined = HTMLElement | undefined> = T | Accessor<T>;

function toValue<T extends HTMLElement | undefined = HTMLElement | undefined>(accessor: MaybeAccessor<T>): T {
	return typeof accessor === 'function' ? accessor() : accessor;
}

export function usePositioning(
	trigger: MaybeAccessor,
	target: MaybeAccessor,
	options: Partial<ComputePositionConfig> = {},
) {
	if (isServer) {
		return;
	}

	let cleanup: () => void;

	createEffect(async () => {
		const reference = toValue(trigger);
		const el = toValue(target);

		async function update() {
			if (reference && el) {
				const pos = await computePosition(reference, el, {
					...options,
					middleware: [flip(), offset(4), ...(options.middleware ?? [])],
				});

				el.style.left = `${pos.x}px`;
				el.style.top = `${pos.y}px`;
			}
		}

		if (reference && el) {
			update();
			cleanup = autoUpdate(reference, el, update);
		} else if (cleanup) {
			cleanup();
		}
	});
}
