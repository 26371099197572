import { createStore, produce } from 'solid-js/store';
import { createContext, createEffect, createUniqueId, onCleanup, onMount, useContext } from 'solid-js';
import { Dynamic, isServer } from 'solid-js/web';
import { twMerge } from '@troon/tailwind-preset/merge';
import { Link } from '../components/link';
import { safeTransition } from '../modules/view-transitions';
import { Popover, PopoverProvider, renderPopover, setRenderPopover, usePopover, usePopoverTrigger } from './popover';
import type { Accessor, Component, JSX, ParentProps } from 'solid-js';
import type { Placement } from '@floating-ui/dom';
import type { IconProps } from '@troon/icons';

type Items = { focused: string | undefined; selected: string | undefined; ids: Array<string> };
type ItemStore = ReturnType<typeof createStore<Items>>;

const Context = createContext<{
	id: string;
	items: ItemStore;
	triggerRef: Accessor<HTMLElement | undefined>;
}>({
	id: '',
	items: createStore<Items>({ focused: undefined, selected: undefined, ids: [] }),
	triggerRef: () => undefined,
});

export function MenuTrigger(props: ParentProps) {
	const id = createUniqueId();
	const [itemStore, setItemStore] = createStore<Items>({
		focused: undefined,
		selected: undefined,
		ids: [],
	});
	const trigger = usePopoverTrigger('menu', ['click'], {
		id,
	});

	return (
		<PopoverProvider {...trigger}>
			<Context.Provider value={{ id: trigger.id, items: [itemStore, setItemStore], triggerRef: trigger.ref[0] }}>
				{props.children}
			</Context.Provider>
		</PopoverProvider>
	);
}

type Props = ParentProps<{
	placement?: Placement;
}>;

export function Menu(props: Props) {
	const ctx = useContext(Context);

	function handleKeyDown(e: KeyboardEvent) {
		const [, setItemStore] = ctx.items;

		// Do not handle anything with meta keys
		if (!renderPopover[ctx.id] || e.metaKey || e.shiftKey || e.ctrlKey || e.altKey) {
			return;
		}

		if (e.key === 'Escape') {
			e.preventDefault();
			e.stopPropagation();
			safeTransition(() => {
				setRenderPopover(ctx.id, false);
			});
			return;
		}

		if (e.key === 'Tab') {
			safeTransition(() => {
				setRenderPopover(ctx.id, false);
				ctx.triggerRef()?.focus();
			});
			return;
		}

		if (e.key === 'ArrowDown') {
			e.preventDefault();

			setItemStore(
				produce((s) => {
					const idx = s.ids.indexOf(s.focused ?? '');
					const next = idx < s.ids.length - 1 ? idx + 1 : 0;
					s.focused = s.ids[next];
				}),
			);
			return;
		}
		if (e.key === 'ArrowUp') {
			e.preventDefault();
			setItemStore(
				produce((s) => {
					const idx = s.ids.indexOf(s.focused ?? '');
					const next = idx > 0 ? idx - 1 : s.ids.length - 1;
					s.focused = s.ids[next];
				}),
			);
			return;
		}
	}

	onMount(() => {
		if (!isServer) {
			document.addEventListener('keydown', handleKeyDown);
		}
	});

	onCleanup(() => {
		if (!isServer) {
			document.removeEventListener('keydown', handleKeyDown);
		}
	});

	return (
		<Popover placement={props.placement}>
			{(spread) => (
				<ul
					{...(spread as unknown as JSX.HTMLAttributes<HTMLUListElement>)}
					class="absolute z-50 flex max-w-max flex-col divide-y divide-solid divide-neutral-500 overflow-y-auto overscroll-contain rounded-lg border border-neutral-300 bg-white shadow-md vt-menu"
				>
					{props.children}
				</ul>
			)}
		</Popover>
	);
}

type ItemProps = ParentProps & {
	class?: string;
	icon?: Component<IconProps>;
} & ({ onSelect: () => void; href?: never } | { href: string; onSelect?: never });

export function MenuItem(props: ItemProps) {
	const id = createUniqueId();
	let ref: HTMLElement;
	const {
		items: [itemStore, setItemStore],
	} = useContext(Context);

	const popover = usePopover();

	onMount(() => {
		setItemStore(produce((s) => s.ids.push(id)));
	});

	onCleanup(() => {
		setItemStore(
			produce((s) => {
				s.ids = s.ids.filter((i) => i !== id);
			}),
		);
	});

	createEffect(() => {
		if (itemStore.focused === id) {
			ref!.focus();
		}
	});

	return (
		<li role="presentation">
			<Dynamic
				component={(forwardProps) => (props.onSelect ? <button {...forwardProps} /> : <Link {...forwardProps} />)}
				id={id}
				role="menuitem"
				tabindex={0}
				onMouseMove={() => {
					setItemStore('focused', id);
				}}
				class={twMerge(
					'flex w-full min-w-64 grow flex-nowrap items-center justify-start gap-x-2 whitespace-nowrap px-4 py-3 font-medium text-black outline-none transition-all duration-200 focus:bg-brand-100 active:bg-brand-100 disabled:bg-transparent disabled:opacity-50',
					props.class,
				)}
				ref={ref!}
				href={props.href}
				onClick={() => {
					if (props.onSelect) {
						props.onSelect();
					}
					popover.close();
				}}
			>
				{props.icon ? <Dynamic component={props.icon} class="text-brand" /> : null}
				{props.children}
			</Dynamic>
		</li>
	);
}

export function MenuSeparator() {
	return (
		<li role="presentation">
			<hr class="border-neutral" />
		</li>
	);
}
