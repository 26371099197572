import { Show, createContext, createSignal, createUniqueId, onCleanup, onMount, useContext } from 'solid-js';
import { Portal } from 'solid-js/web';
import { createStore } from 'solid-js/store';
import { FocusableProvider } from '../providers/focusable';
import { usePositioning } from '../providers/positioning';
import type { Accessor, JSX, ParentProps } from 'solid-js';

const Context = createContext<{ id: string; triggerRef?: Accessor<HTMLElement | undefined> }>({
	id: '',
	triggerRef: () => undefined,
});

const [store, setStore] = createStore<Record<string, boolean>>({});

type TriggerProps = ParentProps;

export function TooltipTrigger(props: TriggerProps) {
	const id = createUniqueId();
	const triggerProps = useTrigger(id);
	const [triggerRef, setTriggerRef] = createSignal<HTMLButtonElement>();

	return (
		<Context.Provider value={{ id, triggerRef }}>
			<FocusableProvider {...triggerProps} ref={setTriggerRef}>
				{props.children}
			</FocusableProvider>
		</Context.Provider>
	);
}

export function Tooltip(props: ParentProps) {
	const ctx = useContext(Context);

	return (
		<Portal>
			<Show when={store[ctx.id]}>
				<TooltipInner {...props} />
			</Show>
		</Portal>
	);
}

function TooltipInner(props: ParentProps) {
	const ctx = useContext(Context);
	const [ref, setRef] = createSignal<HTMLElement>();

	function handleKeyDown(e: KeyboardEvent) {
		if (ref() && e.key === 'Escape') {
			e.stopPropagation();
			setStore(ctx.id, false);
		}
	}

	usePositioning(ctx.triggerRef!, ref, { placement: 'top' });

	onMount(() => {
		if (!ctx.triggerRef) {
			return;
		}
		window.document.addEventListener('keydown', handleKeyDown, true);
	});

	onCleanup(() => {
		window.document.removeEventListener('keydown', handleKeyDown, true);
	});

	return (
		<div
			id={ctx.id}
			class="pointer-events-none absolute z-50 select-none rounded border border-neutral-500 bg-white px-2 py-1 shadow-md backdrop-blur-sm"
			ref={setRef!}
			role="tooltip"
		>
			{props.children}
		</div>
	);
}

function useTrigger(id: string): JSX.HTMLAttributes<HTMLElement> {
	function onMouseEnter() {
		setStore(id, true);
	}

	function onMouseLeave() {
		setStore(id, false);
	}

	function onFocus() {
		setStore(id, true);
	}

	function onBlur() {
		setStore(id, false);
	}

	return {
		'aria-describedby': id,
		onBlur,
		onFocus,
		onMouseEnter,
		onMouseLeave,
	};
}
