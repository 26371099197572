import { createContext, createEffect, createSignal, createUniqueId, Show, useContext } from 'solid-js';
import { Portal } from 'solid-js/web';
import { createStore } from 'solid-js/store';
import { size } from '@floating-ui/dom';
import { FocusableProvider } from '../providers/focusable';
import { usePositioning } from '../providers/positioning';
import { safeTransition } from '../modules/view-transitions';
import { Underlay } from './underlay';
import type { SetStoreFunction } from 'solid-js/store';
import type { Accessor, ParentProps, Signal, JSX } from 'solid-js';
import type { Placement } from '@floating-ui/dom';

type CTX = {
	close: () => void;
	id: string;
	triggerRef: Accessor<HTMLElement | undefined>;
	role: 'dialog' | 'menu' | 'listbox';
	ref: Signal<HTMLElement | undefined>;
};
export const PopoverContext = createContext<CTX>({
	close: () => {},
	id: '',
	triggerRef: () => undefined,
	role: 'dialog',
	ref: [() => undefined, () => {}] as Signal<HTMLElement | undefined>,
});

export type PopoverProps = {
	props: [Omit<JSX.HTMLAttributes<HTMLElement>, 'children'>, SetStoreFunction<JSX.HTMLAttributes<HTMLElement>>];
	ref: Signal<HTMLElement | undefined>;
	id: string;
	role: 'dialog' | 'menu' | 'listbox';
};

type Props = ParentProps<PopoverProps>;

export const [renderPopover, setRenderPopover] = createStore<Record<string, boolean>>({});

export function PopoverProvider(props: Props) {
	const ref = createSignal<HTMLElement>();
	return (
		<PopoverContext.Provider
			value={{
				close: () => {
					safeTransition(() => {
						setRenderPopover(props.id as string, false);
					});
				},
				id: props.id,
				triggerRef: props.ref[0],
				role: props.role,
				ref,
			}}
		>
			<FocusableProvider {...props.props[0]} ref={props.ref[1]}>
				{props.children}
			</FocusableProvider>
		</PopoverContext.Provider>
	);
}

type ContainerProps = {
	children: (props: JSX.HTMLAttributes<HTMLDivElement>) => JSX.Element;
	placement?: Placement | null;
	sameSize?: boolean;
};

export function Popover(props: ContainerProps) {
	const ctx = useContext(PopoverContext);
	if (props.placement !== null) {
		usePositioning(ctx.triggerRef, ctx.ref[0], {
			placement: props.placement ?? 'bottom-start',
			middleware: props.sameSize
				? [
						size({
							apply({ rects, elements }) {
								Object.assign(elements.floating.style, {
									width: `${rects.reference.width}px`,
								});
							},
						}),
					]
				: [],
		});
	}

	return (
		<Portal>
			<Show when={renderPopover[ctx.id]}>
				<>
					<Underlay
						onClick={() => {
							safeTransition(() => {
								setRenderPopover(ctx.id, false);
							});
						}}
					/>

					{props.children({
						role: ctx.role,
						ref: ctx.ref[1],
						id: ctx.id,
						'aria-labelledby': ctx.triggerRef()?.id,
						class:
							'vt-menu pointer-events-auto absolute z-50 flex w-min flex-col overflow-y-auto overscroll-contain rounded-lg border border-neutral-300 bg-white shadow-md',
					})}
				</>
			</Show>
		</Portal>
	);
}

export function usePopover() {
	return useContext(PopoverContext);
}

export function usePopoverTrigger(
	role: 'dialog' | 'menu' | 'listbox',
	trigger: Array<'click' | 'focus' | 'hover'>,
	props: Omit<JSX.HTMLAttributes<HTMLElement>, 'children'>,
): PopoverProps {
	const triggerId = createUniqueId();
	const popoverId = createUniqueId();
	const ref = createSignal<HTMLElement>();

	const [attrs, setAttrs] = createStore<Omit<JSX.HTMLAttributes<HTMLElement>, 'children'>>({
		id: triggerId ?? props.id,
		'aria-haspopup': true,
		'aria-controls': undefined,
		'aria-expanded': false,
		onClick: trigger.includes('click')
			? (e) => {
					// @ts-expect-error claims to not be callable ¯\_(ツ)_/¯
					props.onClick && props.onClick(e);
					if (e.defaultPrevented) {
						return;
					}
					if (e.metaKey || e.ctrlKey || e.shiftKey) {
						return;
					}

					e.preventDefault();

					if (window.innerWidth < 600) {
						e.currentTarget?.scrollIntoView(true);
					}

					safeTransition(() => {
						setRenderPopover(popoverId, true);
					});
				}
			: undefined,

		onFocus: trigger.includes('focus')
			? (e) => {
					// @ts-expect-error claims to not be callable ¯\_(ツ)_/¯
					props.onFocus && props.onFocus(e);
					if (e.defaultPrevented) {
						return;
					}
					setTimeout(() => {
						safeTransition(() => {
							setRenderPopover(popoverId, true);
						});
					}, 1000);
				}
			: undefined,

		onBlur: (e) => {
			const popover = document.getElementById(popoverId);
			if (popover && e.relatedTarget && popover.contains(e.relatedTarget as HTMLElement)) {
				return;
			}

			// @ts-expect-error claims to not be callable ¯\_(ツ)_/¯
			props.onBlur && props.onBlur(e);
			if (e.defaultPrevented) {
				return;
			}
			safeTransition(() => {
				setRenderPopover(popoverId, false);
			});
		},
		onMouseEnter: trigger.includes('hover')
			? (e) => {
					// @ts-expect-error claims to not be callable ¯\_(ツ)_/¯
					props.onMouseEnter && props.onMouseEnter(e);
					if (e.defaultPrevented) {
						return;
					}
					safeTransition(() => {
						setRenderPopover(popoverId, true);
					});
				}
			: undefined,

		onMouseLeave: trigger.includes('hover')
			? (e) => {
					// @ts-expect-error claims to not be callable ¯\_(ツ)_/¯
					props.onMouseLeave && props.onMouseLeave(e);
					if (e.defaultPrevented) {
						return;
					}
					safeTransition(() => {
						setRenderPopover(popoverId, false);
					});
				}
			: undefined,
		...props,
	});

	setRenderPopover(popoverId, false);

	createEffect(() => {
		if (renderPopover[popoverId]) {
			setAttrs({ 'aria-controls': popoverId, 'aria-expanded': true, 'aria-owns': popoverId });
		}
	});

	return { props: [attrs, setAttrs], ref, id: popoverId, role };
}
